import {
  Box,
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import LazyLoad from 'react-lazyload'
import { TrackedLink } from '@flock/shared-ui'
import { navigate } from 'gatsby'

import HowItWorks1 from '../../images/how-it-works-1.webp'
import HowItWorks2 from '../../images/how-it-works-2.webp'
import HowItWorks3 from '../../images/how-it-works-3.webp'
import HowItWorks4 from '../../images/how-it-works-4.webp'
import HowItWorks5 from '../../images/how-it-works-5.webp'

import SectionLayout from '../SharedComponents/SectionLayout'
import { hasIntersectionObserver, useOnScreen } from '../utils'

type SharedStepProps = {
  number: string
  title: string
  description1: string
  description2: string
  imgSrc: string
}

type StepProps = SharedStepProps & {
  dynamicImgSrc: string
  divRef: React.Ref<any>
  showFallbackImages: boolean
  idx: number
}

const Step = (props: StepProps) => {
  const {
    number,
    title,
    description1,
    description2,
    imgSrc,
    showFallbackImages,
    idx,
    divRef,
    dynamicImgSrc,
  } = props

  return (
    <>
      {showFallbackImages ? (
        <Grid
          sm={6}
          item
          key="static-grid"
          display="flex"
          justifyContent="center"
        >
          <LazyLoad once>
            <Box
              component="img"
              src={imgSrc}
              width={448}
              height={448}
              alt={title}
            />
          </LazyLoad>
        </Grid>
      ) : (
        <Grid
          sm={6}
          item
          key="dynamic-grid"
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: 'sticky',
            top: '8%',
            height: '85vh',
            zIndex: idx === 0 ? 999 : 1,
          }}
        >
          {dynamicImgSrc && idx === 0 && (
            <>
              <LazyLoad once>
                <Box
                  component="img"
                  src={idx === 0 ? dynamicImgSrc : undefined}
                  width={448}
                  height={448}
                  alt={title}
                />
              </LazyLoad>
            </>
          )}
        </Grid>
      )}
      <Grid
        item
        sm={6}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box display="flex" ref={divRef}>
          <Box display="flex" flexDirection="column" gap="16px">
            <Box display="flex" flexDirection="column">
              <Typography
                variant="h1"
                color="lavender.main"
                fontSize="3rem"
                lineHeight="3.5rem"
              >
                {number}.
              </Typography>
              <Typography
                variant="h2"
                fontSize="3rem"
                color="midnightBlue.main"
              >
                {title}
              </Typography>
            </Box>

            <Typography variant="p1" fontSize="1.2rem" pl="32px">
              <List sx={{ listStyleType: 'disc', pt: '0px' }}>
                <ListItem sx={{ display: 'list-item', pt: '0px', pb: '0px' }}>
                  {description1}
                </ListItem>
                <ListItem sx={{ display: 'list-item', pt: '0px', pb: '0px' }}>
                  {description2}
                </ListItem>
              </List>
            </Typography>
          </Box>
        </Box>
      </Grid>
    </>
  )
}

const MobileStep = (props: SharedStepProps) => {
  const { number, title, description1, description2, imgSrc } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="32px"
      maxWidth={isMobile ? '320px' : undefined}
      margin="auto"
    >
      <Box
        component="img"
        src={imgSrc}
        width={isMobile ? 304 : 448}
        height={isMobile ? 304 : 448}
        alt={title}
      />
      <Box display="flex">
        <Box display="flex" maxWidth="43px">
          <Typography variant="h2" fontSize="3rem" color="lavender.main">
            {number}.
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="16px"
          paddingLeft="16px"
          maxWidth="464px"
        >
          <Typography variant="h2" fontSize="3rem">
            {title}
          </Typography>
          <Typography variant="h4" fontSize="1rem">
            <List sx={{ listStyleType: 'disc', pt: '0px' }}>
              <ListItem sx={{ display: 'list-item', pt: '0px', pb: '0px' }}>
                {description1}
              </ListItem>
              <ListItem sx={{ display: 'list-item', pt: '0px', pb: '0px' }}>
                {description2}
              </ListItem>
            </List>
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

type HomepageProcessSectionProps = {
  steps?: SharedStepProps[]
}

const HomepageProcessSection = (props: HomepageProcessSectionProps) => {
  const { steps } = props
  const [image, setImage] = useState(HowItWorks1)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const [showFallbackImages, setShowFallbackImages] = useState(false)

  useEffect(() => {
    setShowFallbackImages(!hasIntersectionObserver() || isMobile)
  }, [isMobile])

  const step1Ref = useRef(null)
  const step2Ref = useRef(null)
  const step3Ref = useRef(null)
  const step4Ref = useRef(null)
  const step5Ref = useRef(null)

  const step1Visible = useOnScreen(
    step1Ref,
    '0px',
    theme.breakpoints.down('md')
  )
  const step2Visible = useOnScreen(
    step2Ref,
    '0px',
    theme.breakpoints.down('md')
  )
  const step3Visible = useOnScreen(
    step3Ref,
    '0px',
    theme.breakpoints.down('md')
  )
  const step4Visible = useOnScreen(
    step4Ref,
    '0px',
    theme.breakpoints.down('md')
  )
  const step5Visible = useOnScreen(
    step5Ref,
    '0px',
    theme.breakpoints.down('md')
  )

  useEffect(() => {
    if (
      step5Visible &&
      !step4Visible &&
      !step3Visible &&
      !step2Visible &&
      !step1Visible
    ) {
      setImage(HowItWorks5)
    } else if (
      !step5Visible &&
      step4Visible &&
      !step3Visible &&
      !step2Visible &&
      !step1Visible
    ) {
      setImage(HowItWorks4)
    } else if (
      !step5Visible &&
      !step4Visible &&
      step3Visible &&
      !step2Visible &&
      !step1Visible
    ) {
      setImage(HowItWorks3)
    } else if (
      !step5Visible &&
      !step4Visible &&
      !step3Visible &&
      step2Visible &&
      !step1Visible
    ) {
      setImage(HowItWorks2)
    } else if (
      !step5Visible &&
      !step4Visible &&
      !step3Visible &&
      !step2Visible &&
      step1Visible
    ) {
      setImage(HowItWorks1)
    }
  }, [step1Visible, step2Visible, step3Visible, step4Visible, step5Visible])

  const refs = [step1Ref, step2Ref, step3Ref, step4Ref, step5Ref]

  if (isTablet) {
    return (
      <SectionLayout name="home-process" backgroundColor="gray1.main">
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="32px"
        >
          {steps?.map((step) => (
            <MobileStep key={step.title} {...step} />
          ))}
        </Grid>
      </SectionLayout>
    )
  }

  return (
    <SectionLayout name="home-process" backgroundColor="gray1.main">
      {steps?.map((step, idx) => (
        <Step
          key={step.title}
          {...step}
          divRef={refs[idx]}
          idx={idx}
          dynamicImgSrc={image}
          showFallbackImages={showFallbackImages}
        />
      ))}
    </SectionLayout>
  )
}

export default HomepageProcessSection

const FirstDescription = () => (
  <>
    Provide your address and the property&apos;s condition using our{' '}
    <TrackedLink onClick={() => navigate('/onboarding')}>
      simple questionnaire.
    </TrackedLink>
  </>
)

HomepageProcessSection.defaultProps = {
  steps: [
    {
      imgSrc: HowItWorks1,
      title: 'Get a valuation.',
      number: '1',
      description1: FirstDescription(),
      description2:
        'Our underwriters will make an initial offer within 24 hours, guaranteed.',
    },
    {
      imgSrc: HowItWorks2,
      title: 'Lock in your offer.',
      number: '2',
      description1: 'Discuss your needs with your Overmoon 721 Fund advisor.',
      description2: 'Schedule a home inspection, and finalize your offer.',
      isReverse: true,
    },
    {
      imgSrc: HowItWorks3,
      title: 'Start the exchange.',
      number: '3',
      description1: 'Sign your agreement to invest in the Overmoon 721 Fund.',
      description2: 'Create an investor account.',
    },
    {
      imgSrc: HowItWorks4,
      title: 'Contribute your property.',
      number: '4',
      description1:
        'Provide essential information about your property to the title company.',
      description2:
        'Complete closing with the Overmoon 721 Fund and the title company.',
      isReverse: true,
    },
    {
      imgSrc: HowItWorks5,
      title: 'Welcome to the Overmoon 721 Fund!',
      number: '5',
      description1: 'Enjoy all the benefits of owning real estate.',
      description2: 'No more dealing with toilets, tenants, or trash.',
    },
  ],
}
