import React from 'react'
import HowItWorksSection from '../components/HowItWorksComponents/HowItWorksContentSection'
import Hero from '../components/SharedComponents/Hero/Hero'
import PageWrapper from '../components/SharedComponents/PageWrapper'
import HowItWorksImage from '../images/how-it-works-hero.webp'

const pageTitle = 'Overmoon | How to sign up'
const pageDescription = `It’s a simple 5-step sign up process.`

export const Head = () => (
  <>
    <title>{pageTitle}</title>
    <meta title="title" property="og:title" content={pageTitle} />
    <meta
      name="description"
      property="og:description"
      content={pageDescription}
    />
  </>
)

const HowToSignUp = () => (
  <PageWrapper
    title={pageTitle}
    trackingName="how-to-sign-up"
    headerBackground="gray1.main"
  >
    <Hero
      title="Getting started is easy."
      description="It’s a simple 5-step sign up process, see below."
      imgSrc={HowItWorksImage}
      caption="Destin, FL"
    />
    <HowItWorksSection />
  </PageWrapper>
)

export default HowToSignUp
